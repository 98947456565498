<template>
    <div class="table-responsive">
        <table class="table cart-recap">
            <thead>
                <tr>
                    <th class="cart-product-remove" v-if="!computedOptions.readonly">&nbsp;</th>
                    <th class="cart-product-thumbnail">&nbsp;</th>
                    <th class="cart-product-name">{{ $t("hospitality.rentals.apartment") }}</th>
                    <th class="cart-product-dates">{{ $t("hospitality.rentals.dates") }}</th>
                    <th class="cart-product-subtotal">{{ $t("hospitality.rentals.total") }}</th>
                </tr>
            </thead>
            <tbody>
                <CartRental v-for="(cartProduct, index) in computedOptions.products" :key="index + '-cart-product'" :options="{cartProduct, readonly:computedOptions.readonly}"></CartRental>
            </tbody>
        </table>
    </div>
</template>

<style>

/* We set font size of the header */
.cart-recap th {
    font-size:14px;
}

/* We hide thumbnail for smaller viewport */
@media (max-width: 767.98px) {
    .cart-recap .cart-product-thumbnail {
        display: none;
    }
}

/* We hide price for smaller viewport */
@media (max-width: 575.98px) {
    .cart-recap .cart-product-price {
        display: none;
    }
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import CartRental from './CartRental.vue';

/** @cmsOptions */
export interface RentalsOptions {
    products:any[]
    readonly:boolean
}

/** @cmsLabels */
export interface RentalsLabels {
}

/** @cmsSlots */
export interface RentalsSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<RentalsOptions>,
        labels: {
          type: Object as PropType<RentalsLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<RentalsSlots>
    },
    components: {
        CartRental
    },
    setup(props, context) {
        const app = getApp();
        const $store = useStore();

        const computedOptions:RentalsOptions = {
            readonly : false,
            products : [],
            ...props.options,
        };

        const labels:RentalsLabels = {
            ...props.labels
        }

        return {
            labels,
            computedOptions: {
              ...computedOptions
            }
        }

    }
})

</script>