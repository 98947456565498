/*
* This method return a date beginning of the day
*/
export function startDay(date:Date):Date {
    var date = new Date(date);
    date.setUTCHours(0,0,0,0);
    return date;
};

/*
 * This method return a date end of the day
 */
export function endDay(date:Date):Date {
    var date = new Date(date);
    date.setUTCHours(23,59,59,999);
    return date;
};

/*
 * This method return a date beginning of the month
 */
export function startMonth(date:Date):Date {
    var year = date.getUTCFullYear();
    var month = date.getUTCMonth();
    var date = new Date(Date.UTC(year,month,1));
    date.setUTCHours(0,0,0,0);
    return date;
};

/*
 * This method return a date end of the day
 */
export function endMonth(date:Date):Date {
    var year = date.getUTCFullYear();
    var month = date.getUTCMonth();
    var date = new Date(Date.UTC(year,month+1,0));
    date.setUTCHours(23,59,59,999);
    return date;
};

/*
 * This method return a date beginning of the year
 */
export function startYear(date:Date):Date {
    var year = date.getUTCFullYear();
    var yearStart = new Date(year+"-01-01T00:00:00.000Z");
    return yearStart;
};

/*
 * This method return a date end of the year
 */
export function endYear(date:Date):Date {
    var year = date.getUTCFullYear();
    var yearEnd = new Date(year+"-12-31T23:59:59.999Z");
    return yearEnd;
};

/**
 * This method adds days to current date in without changing the local time
 * @param date 
 * @param days 
 * @returns 
 */
export function addDays(date:Date, days:number) {
    date.setUTCDate(date.getUTCDate() + days);
    return date;
}

/**
 * This method removes days to current date in without changing the local time
 * @param date 
 * @param days 
 * @returns 
 */
export function removeDays(date:Date, days:number) {
    date.setUTCDate(date.getUTCDate() - days);
    return date;
}

/**
 * This method adds minutes to current date in without changing the local time
 * @param date 
 * @param days 
 * @returns 
 */
export function addMinutes(date:Date, minutes:number) {
    date.setUTCMinutes(date.getUTCMinutes() + minutes);
    return date;
}

/**
 * This method removes minutes to current date in without changing the local time
 * @param date 
 * @param days 
 * @returns 
 */
export function removeMinutes(date:Date, minutes:number) {
    date.setUTCMinutes(date.getUTCMinutes() - minutes);
    return date;
}

/*
 * This method return the difference between two dates
 */
export function diff(date1:Date, date2:Date) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    const diffTime = Math.abs(date1.getTime() - date2.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60)); 
    const diffMinutes = Math.floor(diffTime / (1000 * 60)); 

    return {
        time: diffTime,
        days: diffDays,
        hours: diffHours,
        minutes: diffMinutes
    }
}