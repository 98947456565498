<template>
    <tr class="cart_item" ref="cartProduct">
        <td class="cart-product-remove" v-if="!computedOptions.readonly">
            <button class="button button-dark button-rounded ladda-button product-remove" data-style="zoom-in" @click="onRemoveClick" :title="$t('hospitality.rental.remove-button_title')"><i class="icon-trash2 m-0"></i></button>
        </td>

        <td class="cart-product-thumbnail">
            <div><img :src="thumbnailURL" :alt="getLocalizedText(computedOptions.cartProduct.apartment.title)"></div>
        </td>

        <td class="cart-product-name">
            <div class="title">{{getLocalizedText(computedOptions.cartProduct.apartment.title)}}</div>
        </td>

        <td class="cart-product-dates">
            <div>{{ $t("hospitality.selection.check-in") }} <b> {{ formatDate(computedOptions.cartProduct.startDate) }}</b></div>
            <div> {{ $t("hospitality.selection.check-out") }} <b> {{ formatDate(computedOptions.cartProduct.endDate) }}</b></div>
            <div>{{ $t("hospitality.selection.number-of-nights") }} <b>  {{ numberOfNights }} </b></div>
        </td>

        <td class="cart-product-subtotal">
            <span class="amount">{{ formatPriceAmount(computedOptions.cartProduct.amount) }}</span>
        </td>
    </tr>
</template>

<style>

/* We set the size of the picture */
td.cart-product-thumbnail {
    display:flex;
}

td.cart-product-thumbnail > div {
    display: block;
    width: auto;
    height: 95px;
}

/* We set the size of the picture */
td.cart-product-thumbnail > div img {
    height: 95px;
    width:auto;
    border: 0px;
    margin-right:auto;
    margin-left:auto;
}

/* We set default style for product name cell */
td.cart-product-name {
    font-weight: 700;
    font-size: 13px;
    color: var(--body-text-color);
}

/* We set the font size and vertical alignement of table cell */
tr.cart_item td {
    font-size: 14px;
    vertical-align: middle;
}

/* We adjust the style of the subtitle in product name */
td.cart-product-name .subtitle {
    font-weight: 500;
    font-style: italic;
}

/* We remove some space for the remove button and subtotal */
@media (max-width: 767.98px) {
    tr.cart_item td.cart-product-remove, .cart td.cart-product-subtotal {
        padding:0px !important;
    }
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import * as Ladda from 'ladda';
import { diff } from '@igotweb-node-api-utils/date';


/** @cmsOptions */
export interface CartRentalOptions {
    cartProduct:any,
    readonly?:boolean
}

/** @cmsLabels */
export interface CartRentalLabels {
}

/** @cmsSlots */
export interface CartRentallots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<CartRentalOptions>,
        labels: {
          type: Object as PropType<CartRentalLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<CartRentallots>
    },
    components: {
        
    },
    setup(props, context) {
        const app = getApp();
        const store = useStore();

        const cartProduct:Ref<HTMLElement|null> = ref(null);

        const computedOptions:CartRentalOptions = {
            readonly : false,
            ...props.options!,
        };

        const labels:CartRentalLabels = {
            ...props.labels
        }

        const currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        var laddaSubmit:Ladda.LaddaButton|null = null;

        onMounted(() => {
            if(!computedOptions.readonly) {
                // @ts-ignore
                var button:HTMLButtonElement|null = cartProduct.value.querySelector( 'button.ladda-button' );
                laddaSubmit = Ladda.create(button!);
            }
        })

        const thumbnailURL = app.$shop.product.getThumbnailsURLs(computedOptions.cartProduct, require('@public/img/vigneron-online/no-photo.jpeg'))[0];

        const onRemoveClick = (evt:Event) => {
            evt.preventDefault();

            laddaSubmit!.start();
            app.$hospitality.cart.removeRental(computedOptions.cartProduct).then((response:any) => {
                laddaSubmit!.stop();
            });
        }

        const formatDate = (date:Date) => {
            return formatDay(date, currentLanguageCode, {type: FormatDateType.LONG});
        }

        const numberOfNights = diff(computedOptions.cartProduct.startDate,computedOptions.cartProduct.endDate).days;

        return {
            cartProduct,
            labels,
            computedOptions: {
              ...computedOptions
            },
            numberOfNights,
            thumbnailURL,
            formatDate,
            onRemoveClick
        }

    }
})
</script>